import { createGlobalStyle } from "styled-components";
import { color } from "styles/color";

const GlobalStyle = createGlobalStyle`
   
  body {
    font-family: "Vodafone Rg", sans-serif;
    overflow-x: hidden;

    #root {
      height: 100vh;
      overflow-x: clip; // prevents (unnecessary) horizontal scroll
    }
  }
  iframe{
    display: none;
  }
  
  .padding-0 {
    padding:0;
  }
  ul {
     margin: 0;
    padding: 0;
  }
  .font-default-regular {
    font-family: "Vodafone Rg", sans-serif;
  }

  .font-default-regular-lite {
    font-family: "Vodafone Lt", sans-serif;
  }

  .font-default-regular-bold {
    font-family: "Vodafone_Rg_B", sans-serif;
  }
  iframe {
    display: none;
  }
  .cards-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    justify-content: center;
  }

  .heading-big-lite {
    font-size: 40px;
    color: ${color.monochrome.darkGrey};
    font-family: "Vodafone Lt", sans-serif;
  }
  .heading-medium-bold {
    font-size: 28px;
    color: ${color.monochrome.darkGrey};
    font-family: "Vodafone_Rg_B", sans-serif;
  }
  .heading-medium-regular {
    font-size: 28px;
    color: ${color.monochrome.darkGrey};
    font-family: "Vodafone Rg", sans-serif;
  }
.heading-medium-lite {
    font-size: 28px;
    color: ${color.monochrome.darkGrey};
    font-family: "Vodafone Lt", sans-serif;
  }
  .para-regular {
    font-size: 18px;
    font-family: "Vodafone Rg", sans-serif;
    color: ${color.monochrome.darkGrey};
    margin-bottom: 0
  }

   .heading-small-lite {
    font-size: 18px;
    font-family: "Vodafone Lt", sans-serif;
    color: ${color.monochrome.darkGrey}
  }

  .heading-small-bold {
    font-size: 18px;
    font-family: "Vodafone_Rg_B", sans-serif;
    color: ${color.monochrome.darkGrey}
  }
  .spacing-wrapper {
    margin: 0 auto;
    padding: 0 32px;
    > h2 {
      margin-bottom: 48px;
      line-height: 1.21;
    }
    &.small {
      padding: 0 16px;
      > h2 {
        padding: 0 16px;
      }
    }
  }
  .modal-backdrop-bg{
    background-color: rgba(51,51,51,0.7);
    &.show {
      opacity: 1
    }
  }
  
  .ant-popover-arrow{
    display: none !important;
  }
  .ant-popover-inner{
    border-radius: 6px !important;
    box-shadow: 0 0 16px rgba(0,0,0,0.25) !important;
  }
  ::-webkit-scrollbar {
    width: 1px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: tranparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #4A4d4e;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #4A4d4e;
  }
  .cursor-pointer{
    cursor: pointer;
  }
  .modal-700 {
    width: 700px;
    max-width: none!important;
  }
  .ant-select-item-option-state {
    display: none !important;
  }


  /*** Developer Portal Global Styles ***/
  .contentful-richtext{
    width: 80%;
    h2 {

    }
    h3 {
      font-size: 28px;
      line-height: 34px;
    }
    h4 {
      font-family: "Vodafone_Rg_B", sans-serif;
      margin-bottom: 20px;
    }
    h3 {
      font-family: "Vodafone_Rg_B", sans-serif;
      margin-bottom: 20px;
    }
    ul,ol {
      padding-left: 16px;
      margin-bottom: 40px;
      li {
        margin-bottom: 0px;
        font-size: 24px;
        p {
          margin-bottom: 0;
          font-size: 18px;
        }
      }
    }
    p {
      margin-bottom: 32px;
      font-size: 20px;
      line-height: 32px;
      width: 90%;
    }
    blockquote{
      padding: 5px;
      padding-left: 10px;
      border-left: 5px solid #ccc;
      font-style: italic;
      margin-bottom: 20px;
      p{
        margin-bottom: 0;
        font-size: 20px !important;
      }
    }
    .embedded-asset-contentful {
      margin: 30px 0;
    }
    code {
      color: #929292;
      background-color: #fff;
      padding: 24px 30px;
      border: 1px solid #c6c6c6;
      display: inline-block;
      width: 100%;
      border-radius: 8px;
      white-space: pre-wrap;
      font-size: 18px;
    }
    a.contentful-link {
      color: ${color.primary.red};
    }
    table {
      width: 100%;
      table-layout: fixed;
      color: #4d4d4d;
      margin-bottom: 20px;
      border: none;
      p{
        margin-bottom: 0;
      }
      th{
        color: #fff;
        background-color: #9B9B9B;
      }
      tr{
        border-bottom: 1px solid #D6D9E0;
        border-top: 1px solid #D6D9E0;
      }
      td, th{
        border: 1px solid #D6D9E0;
        padding: 10px;
        p{
          font-size: 18px;
        }
      }
      tbody{
          &:nth-child(odd){
          tr{
            td{

            }
            background-color: #f4f4f4;
          }
        }
      }
    }
    @media (max-width: 1024px) {
      width: 100%;
      p{
        width: 100%;
      }
    }
    @media (max-width: 767px) {
      padding: 0 10px;
      h3 {
        font-size: 20px;
        line-height: 28px;
      }
      p {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 45px;
        width: 100%;
      }
      code {
        width: 100%;
      }
    }
    
  }
  .remark {
    font-size: 24px;
    line-height: 28px;
  }

  /* hack: https://github.com/nolimits4web/swiper/issues/3527 */
  .swiper-slide {
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
  }
`;

export default GlobalStyle;
