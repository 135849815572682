import SearchItem from "pages/developer-portal/search/SearchItem";
import styled from "styled-components";
import { ParsedItemI } from "utils/contentfulSearchUtils";

const Wrapper = styled.div`
  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin-bottom: 15px;
    }
  }
`;

interface PropsI {
  items: ParsedItemI[];
  onClickedItem(item: ParsedItemI): void;
}

const SearchList = ({ items, onClickedItem }: PropsI) => {
  return (
    <Wrapper className="search-list">
      <ul>
        {items.map((item, i: number) => (
          <li key={i}>
            <SearchItem item={item} onClick={onClickedItem} />
          </li>
        ))}
      </ul>
    </Wrapper>
  );
};

export default SearchList;
