import styled from "styled-components";

const FixedHeaderWrapper = styled.header`
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  z-index: 1000;
`;

interface FixedHeaderProps {
  children: React.ReactNode;
}

const FixedHeader = ({ children }: FixedHeaderProps) => {
  return <FixedHeaderWrapper>{children}</FixedHeaderWrapper>;
};

export default FixedHeader;
