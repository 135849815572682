import loadable from "@loadable/component";
const LandingPage = loadable(() => import("pages/dab-landing/LandingPage"));
const ContactUs = loadable(() => import("pages/dab-landing/contact/ContactUs"));
const MyAccount = loadable(() => import("pages/dab-landing/my-account/MyAccount"));
const Ecosystem = loadable(() => import("pages/dab-landing/ecosystems/Ecosystem"));
const Footer = loadable(() => import("pages/dab-landing/Footer"));
const GenericPolicyPage = loadable(() => import("pages/dab-landing/GenericPolicyPage"));
const Header = loadable(() => import("pages/dab-landing/Header"));
const HeaderMobile = loadable(() => import("pages/dab-landing/HeaderMobile"));
const Redirect = loadable(() => import("pages/dab-landing/Redirect"));
const Solutions = loadable(() => import("pages/dab-landing/solutions/Solutions"));
const TopHeader = loadable(() => import("pages/dab-landing/TopHeader"));
const DabArchitecture = loadable(() => import("pages/developer-portal/DabArchitecture"));
const DabDocumentation = loadable(() => import("pages/developer-portal/documentation/DabDocumentation"));
const DabGetStarted = loadable(() => import("pages/developer-portal/getting-started/DabGetStarted"));
const DabTutorials = loadable(() => import("pages/developer-portal/tutorials/DabTutorials"));
const DabCommunity = loadable(() => import("pages/developer-portal/community/DabCommunity"));
const Search = loadable(() => import("pages/developer-portal/search/Search"));
const FourZeroFour = loadable(() => import("pages/developer-portal/error-screens/FourZeroFour"));
const BadRequest = loadable(() => import("pages/developer-portal/error-screens/BadRequest"));
const GenericError = loadable(() => import("pages/developer-portal/error-screens/GenericError"));
const DeveloperWelcome = loadable(() => import("pages/developer-portal/DeveloperWelcome"));
const AboutUs = loadable(() => import("pages/dab-landing/about-us/AboutUs"));

export {
  LandingPage,
  ContactUs,
  MyAccount,
  Ecosystem,
  Footer,
  GenericPolicyPage,
  Header,
  HeaderMobile,
  Redirect,
  Solutions,
  TopHeader,
  DabArchitecture,
  DabDocumentation,
  DabGetStarted,
  DabTutorials,
  DabCommunity,
  Search,
  FourZeroFour,
  BadRequest,
  GenericError,
  DeveloperWelcome,
  AboutUs
};
