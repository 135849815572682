import styled from "styled-components";
import { color } from "styles/color";
import { ParsedItemI } from "utils/contentfulSearchUtils";

const Wrapper = styled.button`
  text-align: left;
  width: 100%;
  border: none;
  background: none;
  font-family: "Vodafone Rg";

  h2 {
    font-size: 28px;
    color: #000000;
    margin-bottom: 20px;
  }

  p {
    color: #25282b;
    font-size: 24px;

    strong {
      color: ${color.primary.red};
    }
  }
`;

interface PropsI {
  item: ParsedItemI;
  onClick(item: ParsedItemI): void;
}

const SearchItem = ({ item, onClick }: PropsI) => {
  function onClickItem() {
    onClick(item);
  }

  return (
    <Wrapper onClick={onClickItem} className="search-item">
      <h2>{item.title}</h2>
      <p dangerouslySetInnerHTML={{ __html: item.body }} />
    </Wrapper>
  );
};

export default SearchItem;
