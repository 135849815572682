import { loadableReady } from "@loadable/component";
import DeveloperPortalContext from "contexts/developerPortalContext";
import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import store from "redux/store";
import GlobalStyle from "styles/global";
import App from "./App";

const persistor = persistStore(store);
const container = document.getElementById("root");
const root = createRoot(container);
loadableReady().then(() => {
  root.render(
    <Provider store={store}>
      <PersistGate loading={"Loading..."} persistor={persistor}>
        <DeveloperPortalContext.DevPortalProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
          <GlobalStyle />
        </DeveloperPortalContext.DevPortalProvider>
      </PersistGate>
    </Provider>
  );
});

if ((module as any).hot) {
  (module as any).hot.accept();
}
