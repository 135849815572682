import { RootState } from "redux/store";
import { AuthDataSliceType, AuthUserType } from "redux/types/authSliceType";
import { SearchDataSliceType } from "redux/types/searchSliceType";
import { ParsedItemI } from "utils/contentfulSearchUtils";

export const selectors = {
  auth() {
    return (state: RootState): AuthDataSliceType => state.auth.data;
  },
  userDetails() {
    return (state: RootState): AuthUserType => state.auth.data.user;
  },
  accessToken() {
    return (state: RootState): string => state.auth.data.accessToken;
  },
  getSearchContent() {
    return (state: RootState): ParsedItemI[] => state.search.data.content;
  },
  getLastSearchedContent() {
    return (state: RootState): ParsedItemI[] => state.search.data.lastSearchedContent;
  },
  getLastSearchedWord() {
    return (state: RootState): string => state.search.data.lastSearchedWord;
  },
  getShowSearchInput() {
    return (state: RootState): boolean => state.search.data.showSearchInput;
  }
};
