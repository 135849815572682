import {
  AboutUs,
  BadRequest,
  ContactUs,
  DabArchitecture,
  DabCommunity,
  DabDocumentation,
  DabGetStarted,
  DabTutorials,
  DeveloperWelcome,
  Footer,
  FourZeroFour,
  GenericError,
  GenericPolicyPage,
  Header,
  HeaderMobile,
  LandingPage,
  MyAccount,
  Redirect,
  Search,
  Solutions,
  TopHeader
} from "AsyncComponents";
import FixedHeader from "components/FixedHeader";
import developerPortalContext from "contexts/developerPortalContext";
import { createBrowserHistory, createMemoryHistory } from "history";
import useContentful from "hooks/useContentful";
import useWindowDimensions from "hooks/window";
import SearchInputWrapper from "pages/developer-portal/search/SearchInputWrapper";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Route, Switch, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { selectors as s } from "redux/selectors";
import { searchActions } from "redux/slice/searchSlice";
import styled from "styled-components";
import "styles/antd-default.css";
import { ParsedItemI } from "utils/contentfulSearchUtils";

export const isServer = !(typeof window !== "undefined" && window.document && window.document.createElement);
export const history = isServer
  ? createMemoryHistory({
      initialEntries: ["/"]
    })
  : createBrowserHistory();

const HeaderSeparator = styled.div<{ dev: boolean; tablet: boolean }>`
  margin-top: ${props => (props.tablet ? "85px" : props.dev ? "162px" : "101px")};
`;

const HeaderSeparatorMobile = styled.div`
  margin-top: 56px;
`;

const App = () => {
  const dispatch = useAppDispatch();
  const showSearchInput = useAppSelector(s.getShowSearchInput());
  const { width }: { width: number } = useWindowDimensions();
  const [developer, setDeveloper] = developerPortalContext.useDevPortal();
  const location = useLocation();
  const { getDeveloperContent } = useContentful();

  useEffect(() => {
    setDeveloper(location.pathname.includes("dev-portal"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleSearchContent, []);

  function handleSearchContent() {
    getDeveloperContent().then((response: ParsedItemI[]) => {
      dispatch(searchActions.addContent(response));
    });
  }

  const { getApplicationTitle } = useContentful();

  useEffect(() => {
    getApplicationTitle().then((response: any) => {
      document.title = response.title;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Row style={{ margin: "0" }}>
        <Col style={{ padding: "0" }}>
          <FixedHeader>
            {width >= 768 && <TopHeader />}
            {width < 1024 ? <HeaderMobile developer={developer} /> : <Header developer={developer} />}
          </FixedHeader>
          {width < 768 ? <HeaderSeparatorMobile /> : <HeaderSeparator dev={developer} tablet={width < 1024} />}
        </Col>
      </Row>

      <Switch>
        <Route path="*/callback" component={Redirect} />
        <Route path="/contact-us" component={ContactUs} />
        <Route path="/contact-us-completion" component={ContactUs} />
        <Route path="/privacy-policy" component={() => <GenericPolicyPage targetId="1CO0Zv8dKd7mRWVafGpkqn" />} />
        <Route path="/terms-of-service" component={() => <GenericPolicyPage targetId="7sHqyOftjbzFQ9N3igdf42" />} />
        <Route path="/my-account" component={MyAccount} />
        <Route path="/community" component={DabCommunity} />
        <Route path="/dev-portal/welcome" component={DeveloperWelcome} />
        <Route path="/dev-portal/dab-architecture" component={DabArchitecture} />
        <Route path="/dev-portal/get-started/how-to" component={DabGetStarted} />
        <Route path="/dev-portal/get-started/download-sdk" exact component={DabGetStarted} />
        <Route path="/dev-portal/get-started/compile-code" exact component={DabGetStarted} />
        <Route path="/dev-portal/get-started/hello-dab" exact component={DabGetStarted} />
        <Route path="/dev-portal/docs" component={DabDocumentation} />
        <Route path="/dev-portal/tutorials" component={DabTutorials} />
        <Route path="/search" component={Search} />
        <Route path="/about-us" component={AboutUs} />
        <Route path="/bad-request" component={BadRequest} />
        <Route path="/error-page" component={GenericError} />
        <Route path="/" exact component={LandingPage} />
        <Route path="*" component={FourZeroFour} />s
      </Switch>

      {showSearchInput && <SearchInputWrapper />}
      <Footer />
    </>
  );
};
export default App;
