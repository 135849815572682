import SearchList from "pages/developer-portal/search/SearchList";
// eslint-disable-next-line no-restricted-imports
import _ from "lodash";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { selectors as s } from "redux/selectors";
import { searchActions } from "redux/slice/searchSlice";
import styled from "styled-components";
import { color } from "styles/color";
import contentfulSearchUtils, { ParsedItemI } from "utils/contentfulSearchUtils";

const SearchBarWrapper = styled.div`
  position: fixed;
  top: 105px;
  right: 1px;
  display: flex;
  flex-direction: column;
  width: 400px;
  background: #fff;
  box-shadow: 0 3px 6px 0 ${color.card.shadow};
  z-index: 9000;

  @media (max-width: 768px) {
    width: 100%;
    top: 55px;
    left: 0;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    top: 85px;
  }

  input {
    border: 1px solid ${color.monochrome.midGrey};
    height: 40px;
    border-radius: 2px;
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    padding: 0 25px;

    button {
      height: 30px;
      border-radius: 5px;

      :hover {
        background: ${color.monochrome.lightGrey};
      }
    }
  }

  .results {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
    max-height: 350px;
    position: relative;

    .content {
      display: flex;
      flex-direction: column;
      flex: 1;

      p.top-results {
        text-align: center;
        height: 20px;
        margin: 0 0 5px 0;
        padding: 0;
        color: ${color.monochrome.midGrey};
      }

      .body {
        flex: 1;
        padding: 25px;

        .search-list {
          h2 {
            font-size: 15px !important;
            color: ${color.monochrome.midGrey};
          }

          ul {
            li {
              margin-bottom: 0px !important;

              .search-item {
                p {
                  margin-left: 15px;
                  font-size: 18px;
                }
              }
            }
          }
        }
      }

      .not-content {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 25px;
      }
    }
  }
`;

const SearchInput = styled.input`
  border: 1px solid ${color.monochrome.midGrey};

  :focus {
    outline: none !important;
  }
`;

const SeeAllArticlesButton = styled.button`
  background: #fff;
  border: 1px solid ${color.monochrome.midGrey};
  width: 100%;
`;

const SearchInputWrapper = () => {
  const dispatch = useAppDispatch();
  const history: RouteComponentProps["history"] = useHistory();
  const fullSearchContentRef = useRef<ParsedItemI[]>([]);
  const [searchedContent, setSearchedContent] = useState<ParsedItemI[]>([]);
  const [lastSearchedWord, setLastSearchedWord] = useState("");
  const lastSearchedContent = useAppSelector(s.getLastSearchedContent());
  const _lastSearchedWord = useAppSelector(s.getLastSearchedWord());

  fullSearchContentRef.current = useAppSelector(s.getSearchContent());

  useEffect(handleLastSearchedWord, [_lastSearchedWord]);
  useEffect(handleSearchedContent, [_lastSearchedWord, lastSearchedContent]);

  function handleSearchedContent() {
    if (!_lastSearchedWord || _.isEmpty(lastSearchedContent)) return setSearchedContent([]);
    setSearchedContent(beautify(_lastSearchedWord, cutContentSize(lastSearchedContent)));
  }

  function handleLastSearchedWord() {
    setLastSearchedWord(_lastSearchedWord);
  }

  function onSearchChange(e: ChangeEvent<HTMLInputElement>) {
    if (!e || !e.target || !e.target.value) {
      setLastSearchedWord("");
      setSearchedContent([]);
      dispatch(searchActions.addLastSearchedWord(""));
      dispatch(searchActions.addLastSearchedContent([]));

      return;
    }

    const { value } = e.target;

    dispatch(searchActions.addLastSearchedWord(value));
    setLastSearchedWord(value);
    search(value.toLowerCase());
  }

  function search(input: string) {
    const filtered = filterSearchContentFromInput(fullSearchContentRef.current, input);
    dispatch(searchActions.addLastSearchedContent(filtered));
  }

  function beautify(input: string, filtered: ParsedItemI[]): ParsedItemI[] {
    const { cut, bold } = contentfulSearchUtils;

    return filtered.map(data => {
      const index = data.body.toLocaleLowerCase().indexOf(input.toLocaleLowerCase());
      const body = `...${bold(cut(index, data.body, 60, 30), input)}...`;

      return { ...data, body };
    });
  }

  function cutContentSize(filtered: ParsedItemI[]): ParsedItemI[] {
    if (filtered.length <= 3) return filtered;
    return filtered.slice(0, 3);
  }

  function filterSearchContentFromInput(content: ParsedItemI[], input: string): ParsedItemI[] {
    return contentfulSearchUtils.filterSearchContentFromInput(fullSearchContentRef.current, content, input);
  }

  function onClickedItem(item: ParsedItemI) {
    dispatch(searchActions.toggleShowSearchInput());
    setTimeout(() => history.push(item.path));
  }

  function seeAllArticles() {
    dispatch(searchActions.toggleShowSearchInput());
    setTimeout(() => history.push("/search"));
  }

  return (
    <SearchBarWrapper>
      <SearchInput
        type="text"
        onChange={onSearchChange}
        value={lastSearchedWord}
        placeholder="Search Developer Portal..."
      />

      {lastSearchedWord && (
        <>
          <div className="results">
            <div className="content">
              <p className="top-results">Top results</p>

              {searchedContent.length > 0 && (
                <div className="body">
                  <SearchList items={searchedContent} onClickedItem={onClickedItem} />
                </div>
              )}

              {searchedContent.length <= 0 && (
                <div className="not-content">
                  <p>Sorry, we couldn’t find search results with</p>
                </div>
              )}
            </div>
          </div>
          <div className="footer">
            <SeeAllArticlesButton type="button" onClick={seeAllArticles}>
              See all articles
            </SeeAllArticlesButton>
          </div>
        </>
      )}
    </SearchBarWrapper>
  );
};

export default SearchInputWrapper;
