export const color = {
  mask: "rgba(0,0,0,0.5)",
  transparent: "transparent",
  card: {
    shadow: "rgba(0, 0, 0, 0.16)"
  },
  primary: {
    grey: "#4a4d4e",
    red: "#e60000",
    white: "#ffffff"
  },
  secondary: {
    darkTurquoise: "#005665",
    turquoise: "#007c92",
    aquaBlue: "#00b0ca",
    aubergine: "#9c2aa0",
    springGreen: "#a8b400",
    freshOrange: "#eb9700",
    lemonYellow: "#fecb00"
  },
  digital: {
    green: "#009900",
    darkGreen: "#207220",
    maroon: "#990000"
  },

  monochrome: {
    black: "#000000",
    midnight: "#222222",
    darkGrey: "#333333",
    anthracite: "#666666",
    midGrey: "#999999",
    platinum: "#afafaf",
    silver: "#cccccc",
    aluminium: "#ebebeb",
    lightGrey: "#f4f4f4"
  }
};
