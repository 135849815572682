import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
// eslint-disable-next-line no-restricted-imports
import _ from "lodash";

export interface ParsedItemI {
  title?: string;
  body?: string;
  bodyLowerCase?: string;
  path?: string;
}

interface SearchContentI {
  [key: string]: {
    title: string;
    path: string;
  };
}

const devPortal = "/dev-portal";
const getStarted = `${devPortal}/get-started`;
const content: SearchContentI = {
  developerGettingStartedHow: {
    title: "Developer > Get Started > How To Get Started",
    path: `${getStarted}/how-to`
  },
  developerGettingStartedHowPage: {
    title: "Developer > Get Started",
    path: `${getStarted}/how-to`
  },
  developerDabArchitecturePage: {
    title: "Developer > DAB Architecture Page",
    path: `${devPortal}/dab-architecture`
  },
  downloadSdkPage: {
    title: "Developer > Get Started > Download SDK",
    path: `${getStarted}/download-sdk`
  },
  sourceCodePage: {
    title: "Developer > Get Started > Install SDK",
    path: `${getStarted}/compile-code`
  },
  helloDabPage: {
    title: "Developer > Get Started > Hello 'DAB' Tutorial",
    path: `${getStarted}/download-sdk`
  }
};

function filterSearchContentFromInput(
  fullSearchContent: ParsedItemI[],
  content: ParsedItemI[],
  input: string
): ParsedItemI[] {
  return content.reduce((acc: ParsedItemI[], { body, bodyLowerCase }, i: number) => {
    if (!body) return acc;

    const data = contains(bodyLowerCase, input);
    if (!data) return acc;

    acc.push(_mapSearchContent(fullSearchContent[i], data.index));
    return acc;
  }, []);
}

function contains(data: string, input: string): any {
  return data.match(input);
}

function map(items: any[]): ParsedItemI[] {
  return _mapParsedItems(_parseItems(items));
}

function bold(data: string, input: string): any {
  return data.replace(new RegExp(`${input}`, "gi"), "<strong>$&</strong>");
}

function cut(from: number, data: string, max = 150, min = 100): string {
  return data.substring(_getMinIndex(from, min), _getMaxIndex(from, data.length, max));
}

function _mapSearchContent(item: ParsedItemI, from: number): ParsedItemI {
  return {
    ...item,
    body: cut(from, item.body)
  };
}

function _getMinIndex(from: number, MIN_CONTENT_CHAR) {
  if (from - MIN_CONTENT_CHAR < 0) return 0;
  else return from - MIN_CONTENT_CHAR;
}

function _getMaxIndex(from: number, len: number, MAX_CONTENT_CHAR) {
  if (from + MAX_CONTENT_CHAR < len) return from + MAX_CONTENT_CHAR;
  else return len;
}

function _parseItems(items: any[]): ParsedItemI {
  const mapped: ParsedItemI = {};

  return items.reduce((acc: ParsedItemI, item) => {
    const { fields, sys } = item;
    const { slug, path, ...rest } = fields;
    const contentType = sys.contentType.sys.id;

    if (!mapped[contentType]) mapped[contentType] = { body: "" };

    const keys = Object.keys(rest);
    keys.reduce((acc: ParsedItemI, key: string) => {
      const value = rest[key];

      if (_isString(value)) acc[contentType].body += ` ${value}`;
      else if (_isRichText(value)) acc[contentType].body += ` ${_handleRichText(value)}`;

      return acc;
    }, acc);

    return acc;
  }, mapped);
}

function _mapParsedItems(parsed: ParsedItemI): ParsedItemI[] {
  return Object.keys(parsed).map(key => {
    const body = _trim(parsed[key].body);

    return {
      body,
      title: content[key].title,
      path: content[key].path,
      bodyLowerCase: body.toLocaleLowerCase()
    };
  });
}

function _isString(value: any): boolean {
  return _.isString(value);
}

function _isRichText(value: any): boolean {
  return !!value.nodeType;
}

function _handleRichText(data: any): string {
  return _removeHtmlTags(documentToHtmlString(data));
}

function _removeHtmlTags(data: any): string {
  return data.replace(/(<([^>]+)>)/gi, "");
}

function _trim(data: string) {
  return data.trim();
}

const contentfulSearchUtils = {
  cut,
  map,
  bold,
  contains,
  filterSearchContentFromInput
};

export default contentfulSearchUtils;
