import React from "react";

const developerPortalContext = React.createContext();

function useDevPortal() {
  const context = React.useContext(developerPortalContext);

  return context;
}

function DevPortalProvider(props) {
  const [devPortal, setDevPortal] = React.useState(false);

  const value = React.useMemo(() => [devPortal, setDevPortal], [devPortal]);

  return <developerPortalContext.Provider value={value} {...props} />;
}

export default { DevPortalProvider, useDevPortal };
