import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { SearchSliceI } from "redux/types/searchSliceType";

const initialState: SearchSliceI = {
  data: {
    content: [],
    lastSearchedContent: [],
    lastSearchedWord: "",
    showSearchInput: false
  }
};

export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    addContent: (state, action: PayloadAction<any>) => {
      state.data.content = action.payload;
    },
    addLastSearchedContent: (state, action: PayloadAction<any>) => {
      state.data.lastSearchedContent = action.payload;
    },
    addLastSearchedWord: (state, action: PayloadAction<any>) => {
      state.data.lastSearchedWord = action.payload;
    },
    toggleShowSearchInput: state => {
      state.data.showSearchInput = !state.data.showSearchInput;
    }
  }
});

// Action creators are generated for each case reducer function
export const searchActions = searchSlice.actions;
export const SearchReducer = searchSlice.reducer;
