import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AuthDataSliceType, AuthSliceType, AuthUserType } from "redux/types/authSliceType";

const initialState: AuthSliceType = {
  data: {
    accessToken: "",
    refreshToken: "",
    signed: false,
    redirectToUrl: "",
    user: {
      id: "",
      firstName: "",
      lastName: "",
      fullName: "",
      initialsLettersFromFullName: "",
      email: "",
      companyName: "",
      role: {
        id: "",
        name: ""
      },
      account: {
        dabAccountId: null,
        dabCustomerName: null
      }
    }
  }
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    sign: (state, action: PayloadAction<any>) => {
      state.data.signed = action.payload;
    },
    redirectTo: (state, action: PayloadAction<any>) => {
      state.data.redirectToUrl = action.payload;
    },
    authenticate: (state, action: PayloadAction<any>) => {
      state.data = map(action.payload, state.data);
    },
    updateUserDetails: (state, action: PayloadAction<any>) => {
      state.data.user = mapUser(action.payload);
    },
    logout: state => {
      state.data = initialState.data;
    }
  }
});

function map(response: any, stateData: any): AuthDataSliceType {
  const { user } = response;

  return {
    accessToken: response.jwt_token,
    refreshToken: response.refresh_token,
    signed: true,
    redirectToUrl: stateData.redirectToUrl,
    user: mapUser(user)
  };
}

function mapUser(response: any): AuthUserType {
  const user = response;
  const { given_name, family_name } = user;
  return {
    id: user.id,
    firstName: given_name,
    lastName: family_name,
    fullName: `${given_name} ${family_name}`,
    initialsLettersFromFullName: `${given_name[0]}${family_name[0]}`.toLocaleUpperCase(),
    email: user.email,
    companyName: user.accounts ? user.accounts.dab_customer_name : "",
    role: {
      id: user.role ? user.role.id : null,
      name: user.role ? user.role.name : null
    },
    account: {
      dabAccountId: user.accounts ? user.accounts.dab_account_id : null,
      dabCustomerName: user.accounts ? user.accounts.dab_customer_name : null
    }
  };
}

// Action creators are generated for each case reducer function
export const authActions = authSlice.actions;
export const AuthReducer = authSlice.reducer;
