/* eslint-disable no-console */
import axios from "axios";
import { createClient } from "contentful";
// eslint-disable-next-line no-restricted-imports
import _ from "lodash";
import contentfulSearchUtils from "utils/contentfulSearchUtils";
import { contentfulAccessToken, contentfulEnvironment, contentfulHost, contentfulSpaceId } from "./../contentfulConfig";

const useContentful = () => {
  const client = createClient({
    space: contentfulSpaceId || "",
    accessToken: contentfulAccessToken || "",
    environment: contentfulEnvironment || "",
    host: contentfulHost || ""
  });
  const getDabArchitecture = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "dabArchitecture"
      });
      return entries;
    } catch (error) {
      console.log(error);
    }
  };

  const getApplicationTitle = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "applicationTitle"
      });
      return entries.items[0].fields;
    } catch (error) {
      console.log(`Application Title error: ${error}`);
    }
  };

  const getDabArchitecturePage = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "developerDabArchitecturePage"
      });
      return entries;
    } catch (error) {
      console.log(`DAB Architecture Page error: ${error}`);
    }
  };

  const getDabArchitectureGetStartedHowPage = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "developerGettingStartedHowPage"
      });
      return entries;
    } catch (error) {
      console.log(`Getting Started Page error: ${error}`);
    }
  };

  const getDownloadSDKPage = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "downloadSdkPage"
      });
      return entries;
    } catch (error) {
      console.log(`Download SDK Page error: ${error}`);
    }
  };

  const getHelloDABPage = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "helloDabPage"
      });
      return entries;
    } catch (error) {
      console.log(`Hello DAB page error: ${error}`);
    }
  };

  const getDocumentationDevicesPage = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "documentationDevicesPage"
      });
      return entries;
    } catch (error) {
      console.log(`Documentation Devices page error: ${error}`);
    }
  };

  const getHowItWorksPage = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "howItWorksPage"
      });
      return entries.items[0].fields;
    } catch (error) {
      console.log(`How it works page error: ${error}`);
    }
  };

  const getHeader = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "portalHeader"
      });
      return entries.items[0].fields;
    } catch (error) {
      console.log(`Header ${error}`);
    }
  };

  const getNavigationChildren = async (targetId: string) => {
    try {
      const entries = await client.getEntries({
        content_type: "navigationEntry",
        "sys.id": targetId
      });
      return entries.items[0].fields;
    } catch (error) {
      console.log(`Navigation ${error}`);
    }
  };

  const getDocsCommonPages = async (pageId: string) => {
    try {
      const entries = await client.getEntries({
        content_type: "documentationCommonPages"
      });
      const entry = entries.items.filter((item: any) => {
        return item.fields.pageId === pageId;
      });
      return entry[0].fields;
    } catch (error) {
      console.log(`Documentation Common Pages error: ${error}`);
    }
  };

  const getDocumentationOverview = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "documentationOverview"
      });
      return entries.items[0].fields;
    } catch (error) {
      console.log(`Docs overview ${error}`);
    }
  };

  const getSourceCodePage = async (targetId: string) => {
    try {
      const entries = await client.getEntries({
        content_type: "sourceCodePage",
        "sys.id": targetId
      });
      return entries.items[0].fields;
    } catch (error) {
      console.log(`Source code page ${error}`);
    }
  };

  const getDabDeveloperExamplesPage = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "dabDeveloperExamples"
      });
      return mapDabDeveloperExamplesPage(entries.items[0].fields);
    } catch (error) {
      console.log(`Docs overview ${error}`);
    }
  };

  const mapDabDeveloperExamplesPage = (response: any) => {
    const { title, subTitle, cards } = response;
    const list = cards.map((card: any) => {
      const { fields } = card;
      const { title, description, image, tags, url: to } = fields;
      const { fields: imageFields } = image;

      return {
        title,
        to,
        description,
        tags: formatDabDeveloperExampleCardTags(tags),
        image: `https:${imageFields.file.url}`
      };
    });

    return { title, subTitle, cards: list };
  };

  const formatDabDeveloperExampleCardTags = (tags: string): string[] => {
    if (!tags) return [];
    if (!_.isString(tags)) return [];

    return tags.split(";");
  };

  const getTutorialsPage = async (pageId: string) => {
    try {
      const entries = await client.getEntries({
        content_type: "tutorialsPage"
      });
      const entry = entries.items.filter((item: any) => {
        return item.fields.pageId === pageId;
      });
      return entry[0].fields;
    } catch (error) {
      console.log(`Tutorials Pages error: ${error}`);
    }
  };

  const getFooter = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "footer"
      });
      return entries.items[0].fields;
    } catch (error) {
      console.log(`Footer ${error}`);
    }
  };

  const getNotLoggedInModal = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "notLoggedInModal"
      });
      return entries.items[0].fields;
    } catch (error) {
      console.log(`Not Logged In Modal: ${error}`);
    }
  };

  const getContactUsCompletion = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "contactUsCompletion"
      });
      return entries.items[0].fields;
    } catch (error) {
      console.log(`Contact Us completion ${error}`);
    }
  };

  const getLatestYamlFile = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "apiDocumentations",
        "fields.version": "Latest"
      });
      return entries;
    } catch (error) {
      console.log(`Get YAML file ${error}`);
    }
  };

  const getLandingPageEcosystemTab = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "landingPageEcosystemTab"
      });

      return mapGetLandingPageEcosystemTab(entries.items[0].fields);
    } catch (error) {
      console.log(`GetLanding Page Ecosystem ${error}`);
    }
  };

  const mapGetLandingPageEcosystemTab = (response: any) => {
    const { title, bodyText, bodyButtonText, bodyButtonUrl, carouselItems, bgImage } = response;

    const list = carouselItems.map((item: any) => {
      const { fields } = item;
      const { toolTipName, toolTipDescription, image } = fields;
      const { fields: imageFields } = image;

      return {
        toolTipName,
        toolTipDescription,
        image: `https:${imageFields.file.url}`
      };
    });

    return { title, bodyText, bodyButtonText, bodyButtonUrl, items: list, bgImage: `https:${bgImage.fields.file.url}` };
  };

  const getOverviewContent = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "overviewLandingPage"
      });
      return entries.items[0].fields;
    } catch (error) {
      console.log(`Overview ${error}`);
    }
  };

  const getSolutionsDab = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "dabSolutions"
      });
      return mapGetSolutionsDab(entries.items[0].fields);
    } catch (error) {
      console.log(`get dabSolutions ${error}`);
    }
  };

  const mapGetSolutionsDab = (response: any) => {
    const { title, accordionItems } = response;

    if (!accordionItems) return { title, items: [] };

    const list = accordionItems.map((item: any) => {
      const { fields } = item;
      const { title, description, actionButtonUrl, actionButtonText, animation, image } = fields;
      const animationUrl = `https:${animation.fields.file.url}`;

      return {
        title,
        description,
        actionButtonUrl,
        actionButtonText,
        animationUrl,
        image: `https:${image.fields.file.url}`
      };
    });

    return { title, items: list };
  };

  const getMediaFile = async (assetId: string) => {
    try {
      const assetInfo = await client.getAsset(assetId);

      const asset = await axios.get(`https:${assetInfo.fields.file.url}`);

      return asset.data;
    } catch (error) {
      console.log(`Getting media file ${error}`);
    }
  };

  const getDeveloperContent = async () => {
    try {
      const entries = await client.getEntries({
        "sys.contentType.sys.id[in]":
          // eslint-disable-next-line max-len
          "developerDabArchitecturePage,developerGettingStartedHowPage,developerGettingStartedHow,downloadSdkPage,sourceCodePage,helloDabPage"
      });

      return contentfulSearchUtils.map(entries.items);
    } catch (error) {
      console.log(`get dabSolutions ${error}`);
    }
  };

  const getMediaFileByUrl = async (url: string) => {
    try {
      const asset = await axios.get(url);
      return asset.data;
    } catch (error) {
      console.log(`Getting media file by url ${error}`);
    }
  };

  const getCommunityPage = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "communityPage"
      });
      return entries.items[0].fields;
    } catch (error) {
      console.log(`Community Page ${error}`);
    }
  };

  const getEventDetailPage = async (pageId: string) => {
    try {
      const entries = await client.getEntries({
        content_type: "eventDetail"
      });
      const entry = entries.items.filter((item: any) => {
        return item.fields.pageId === pageId;
      });
      return entry[0].fields;
    } catch (error) {
      console.log(`Event Detail Page ${error}`);
    }
  };

  const getGithubBannerContent = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "githubBanner"
      });
      return entries.items[0].fields;
    } catch (error) {
      console.log(`Github Banner ${error}`);
    }
  };

  const getNavEntryByPath = async (path: string, level?: number) => {
    try {
      const entries = await client.getEntries({
        content_type: "navigationEntry"
      });
      const entry = entries.items.filter((item: any) => {
        return item.fields.path === path && (level != undefined ? item.fields.level === level : true);
      });
      return entry[0];
    } catch (error) {
      console.log(`Get Nav Entry By path ${error}`);
    }
  };

  const getLinksToEntry = async (id: string) => {
    try {
      const entries = await client.getEntries({
        links_to_entry: id
      });

      return entries.items[0];
    } catch (error) {
      console.log(`Get Nav Entry By path ${error}`);
    }
  };

  const getNewsDetailPage = async (pageId: string) => {
    try {
      const entries = await client.getEntries({
        content_type: "newsDetail"
      });
      const entry = entries.items.filter((item: any) => {
        return item.fields.pageId === pageId;
      });
      return entry[0].fields;
    } catch (error) {
      console.log(`Event Detail Page ${error}`);
    }
  };

  const getErrorPages = async (id: string) => {
    try {
      const entries = await client.getEntries({
        content_type: "errorPagesContent",
        "fields.id": id
      });
      return entries.items[0].fields;
    } catch (error) {
      console.log(`Get Error Pages Content Error: ${error}`);
    }
  };

  const getDeveloperWelcomePage = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "developersWelcomePage"
      });
      return entries.items[0].fields;
    } catch (error) {
      console.log(`Developer Welcome ${error}`);
    }
  };

  const getAllEventsPage = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "allEvents"
      });
      return entries.items[0].fields;
    } catch (error) {
      console.log(`All Events ${error}`);
    }
  };

  const getAboutUsPage = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "aboutUsPage"
      });
      return entries.items[0].fields;
    } catch (error) {
      console.log(`About Us ${error}`);
    }
  };

  return {
    getApplicationTitle,
    getDabArchitecture,
    getDabArchitecturePage,
    getDabArchitectureGetStartedHowPage,
    getDownloadSDKPage,
    getHelloDABPage,
    getDocumentationDevicesPage,
    getHowItWorksPage,
    getHeader,
    getNavigationChildren,
    getDocsCommonPages,
    getDocumentationOverview,
    getSourceCodePage,
    getDabDeveloperExamplesPage,
    getTutorialsPage,
    getFooter,
    getNotLoggedInModal,
    getContactUsCompletion,
    getLatestYamlFile,
    getLandingPageEcosystemTab,
    getOverviewContent,
    getSolutionsDab,
    getMediaFile,
    getDeveloperContent,
    getMediaFileByUrl,
    getCommunityPage,
    getEventDetailPage,
    getGithubBannerContent,
    getNavEntryByPath,
    getLinksToEntry,
    getNewsDetailPage,
    getErrorPages,
    getDeveloperWelcomePage,
    getAllEventsPage,
    getAboutUsPage
  };
};

export default useContentful;
